.gallery_vidoe{
    width: 100%;
    height: 900px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.gallery_vidoe video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}
.gallery_vidoe h2{
    position: relative;
    z-index: 2;
    font-family: var(--fontUltra);
    text-transform: uppercase;
    letter-spacing: 28px;
    font-size: 8rem;
    color: #fff;
}


@media screen and (max-width:600px) {
    .gallery_vidoe{
        height: 600px;
    }
    .gallery_vidoe h2{
        letter-spacing: 10px;
        font-size: 2rem;
    }
}