
.carausel{
    width: 100%;
    height: 950px;
}
.slider{
    width: 100%;
    height: 100%;
    position: relative;
}
.slider img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(30%) brightness(80%); 
}


@media screen and (max-width:600px) {
    .carausel{
        height: 600px;
    }
}