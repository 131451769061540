.gallery_two{
    width: 100%;
    display: flex;
    padding-top: 4px;
}

.gallery_twoBig{
    width: 70%;
    height: 700px;
    overflow: hidden;
    margin-right: 4px;
}
.gallery_twoBig.marg{
    margin-right: 0;
    margin-left: 4px;
}
.gallery_twoBig img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 8s;
}
.gallery_twoBig:hover img{
    transform: scale(1.1);
    filter: grayscale(50%);
}

.gallery_twoSmall{
    width: 30%;
    height: 700px;
}
.gallery_twoSmallItem{
    width: 100%;
    height: 50%;
    overflow: hidden;
}
.gallery_twoSmallItem:first-child{
    margin-bottom: 4px;
    height: calc(50% - 4px);
}

.gallery_twoSmallItem img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 8s;
}
.gallery_twoSmallItem:hover img{
    transform: scale(1.1);
    filter: grayscale(50%);
}


@media screen and (max-width:1600px) {
    .gallery_twoBig{
        height: 550px;
    }
    .gallery_twoSmall{
        height: 550px;
    }
}


@media screen and (max-width:600px) {
    .gallery_two{
        flex-direction: column;
        width: calc(100% - 8px);
        margin: auto;
    }
    .gallery_twoBig{
        width: 100%;
        height: 450px;
        margin-bottom: 4px;
    }
    .gallery_twoSmall{
        width: 100%;
        height: 250px;
        display: flex;
    }
    .gallery_twoSmallItem:first-child{
        margin-right: 4px;
        padding-bottom: 0;
        height: 100%;
    }
    .gallery_twoSmallItem{
        height: 100%;
    }
    .gallery_twoBig.marg{
        margin-right: 0;
        margin-left: 0;
        margin-top: 4px;
    }
  
}