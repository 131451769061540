
.admin{
    width: 100%;
    padding: 20px 100px;
}
.admin_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.admin_title{
    color: dodgerblue;
}
.admin_btn{
    padding: 10px 24px;
    background: crimson;
    color:#fff;
    border-radius: 5px;
    border: none;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.admin_btn:hover{
    opacity: .7;
}
.admin_navbar{
    padding-top: 20px;
    padding-bottom: 6px;
    border-bottom: 1px solid dodgerblue;
    margin-bottom: 10px;
}
.admin_navbarItem{
    margin-right: 50px;
    color: dodgerblue;
    position: relative;
}
.admin_active{
    font-weight: 600;
}
.admin_active::before{
    content: '';
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 100%;
    height: 3px;
    background: dodgerblue;
    border-radius: 6px 6px 0 0 ;
}   



@media screen and (max-width:600px){
    .admin{
        padding: 20px 10px;
    }
}