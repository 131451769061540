@font-face {
    font-family: "Ultra";
    src: url("../../fonts/Ultra/Ultra-Regular.ttf");
}

.home{
    margin-top: -120px;
}
.home_banner{
    width: 100%;
    height: 600px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
}
.home_bannerLeft{
    background: url("../../assets/photos/img1.JPG") no-repeat center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home_bannerRight h2,
.home_bannerLeft h2{
    font-size: 3.5rem;
    font-weight: 300;
    text-transform: uppercase;
    font-family: "Ultra";
    color: #ffffff78;
    transition: .5s;
}
.home_bannerRight:hover h2,
.home_bannerLeft:hover h2{
    color: #fff;
}
.home_bannerRight{
    background: url("../../assets/photos/img2.JPG") no-repeat center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_limited{
    width: 100%;
    background: var(--bgLight);
}
.home_limited h1{
    text-align: center;
    padding: 30px 0;
    text-transform: uppercase;
    font-weight: 300;
}
.home_limitedContainer{
    width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 30px;
}
.home_limitedContainer a{
    width: 480px;
    height: 330px;
    margin: 8px;
}
.home_limitedContainerItem{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home_limitedContainerItem img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(30%);
    transition: .5s;
    position: absolute;
    top: 0;
    left: 0;
}
.home_limitedContainerItem:hover img{
    transform: scale(1.06);
    filter: grayscale(0);

}
.home_limitedContainerItem h2{
    position: relative;
    z-index: 2;
    text-transform: uppercase;
    font-weight: 300;
    color: #fff;
    font-family: "Ultra";
    font-size: 3rem;
    letter-spacing: 8px;
    text-shadow: 0 0 10px #00000088;
    transition: .5s;
}
.home_limitedContainerItem:hover h2{
    opacity: 0;
}
.home_life{
    width: 100%;
    height: 550px;
    background: url("../../assets/photos/1200px-Lower_Manhattan_skyline_-_June_2017.jpg") no-repeat fixed center/cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.home_life::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000aa;
}
.home_life h2{
    color: var(--colorLight);
    z-index: 1;
    font-size: 3rem;
}
.home_life button{
    z-index: 1;
    width: 200px;
    height: 44px;
    margin-top: 30px;
    background: gold;
    border: 1px solid gold;
    outline: none;
    text-transform: uppercase;
    transition: .3s;
}
.home_life button:hover{
    background: var(--bgDark);
    color: gold;
    cursor: pointer;
}






@media screen and (max-width:1600px) {
    .home_limitedContainer{
        width: 95%;
    }
    .home_limitedContainer a{
        width: 380px;
        height: 280px;
        margin: 8px;
    }

}

@media screen and (max-width:600px) {
    .home{
        margin-top: 0;
    }
    .home_banner{
        grid-template-columns: 1fr;
        height: auto;
    }

    .home_bannerRight h2,
    .home_bannerLeft h2{
        font-size: 2rem;
    }
    .home_bannerLeft, .home_bannerRight{
        height: 350px;
    }
    .home_limitedContainer{
        width: 97%;
    }
    .home_limitedContainer a{
        width: 100%;
    }
    .home_limitedContainerItem{
        width: 100%;
        height: 100%;
    }
    .home_life{
        height: 380px;
    }
    .home_life h2{
        font-size: 1.7rem;

    }
}
