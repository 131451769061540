.login{
    width: 100%;
    display: flex;
    justify-content: center;
}
.login_container{
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    padding: 0 50px;
    width: 400px;
    height: 500px;
    margin: 100px auto;
    border-radius: 5px;
    box-shadow: 0 0 20px #77777733;
}
.login_container h1{
    padding: 30px 0;
    text-align: center;
    color: dodgerblue;
}

.login_container label{
    margin-bottom: 6px;
    color: #555;
    font-size: 14px;
}
.login_container input{
    width: 100%;
    height: 40px;
    text-indent: 10px;
    border-radius: 4px;
    border: none;
    box-shadow: 0 0 8px #77777755;
    outline: none;
}
.login_container input:nth-child(3){
    margin-bottom: 30px;
}

.login_involid{
    font-size: 14px;
    color: crimson;
    margin-bottom: 24px;
    user-select: none;
    opacity: 0;
}
.login_container input:focus{
    box-shadow: 0 0 12px #77777777;
}
.login_container input::placeholder{
    color: #999;
}

.login_container button{
    width: 100%;
    height: 40px;
    border-radius: 4px ;
    border: 1px solid dodgerblue;
    background-color: dodgerblue;
    color: #fff;
    text-transform: uppercase;
}
.login_container button:hover{
    opacity: .8;
    cursor: pointer;
}