
.footer{
    width: 100%;
    height: 300px;
    background: var(--bgLight);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.footer h2{
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 300;
    padding-bottom: 30px;
}
.footer_icons a{
    display: inline-block;
    margin: 0 8px;
    font-size: 1.5rem;
    color: var(--bgDark);
    transition: .3s;
    opacity: .7;
}
.footer_icons a:hover{
    opacity: 1;
    transform: scale(1.1);
}
